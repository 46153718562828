import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { FormFieldErrorComponent } from '../../utilities/form-field-error/form-field-error.component';
import { LoaderComponent } from '../../utilities/loader/loader.component';

@Component({
  selector: 'aup-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    FormFieldErrorComponent,
    RouterLink,
    TranslateModule,
  ],
  providers: [TranslateModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required]),
    stayLoggedIn: new FormControl<boolean>(false),
  });
  subscription!: Subscription;
  loading: boolean = false;
  submitCount: number = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    public translationService: TranslateService,
  ) {}
  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      return this.subscription.unsubscribe();
    }
  }

  onSubmit(): void {
    if (this.loginForm.valid || this.submitCount > 0) {
      this.submitCount++;
      this.loading = true;

      this.authService.login(this.loginForm.value).subscribe({
        next: (_) => {
          this.loading = false;
        },
        error: (e) => {
          console.error('Login error:', e);
          this.loginForm.controls['password'].setValue('');
          this.loginForm.controls['password'].setErrors({
            'invalid-cred': true,
          });
          this.loginForm.controls['email'].setErrors({ 'invalid-cred': true });
          this.loading = false;
        },
      });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }
}
