import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ModalService } from '../../../../../services/modal.service';
import { WaterMeterService } from '../../../../../services/water-meter.service';
import { ModalButtonComponent } from '../../../buttons/modal-button/modal-button.component';
import { FormFieldErrorComponent } from '../../../form-field-error/form-field-error.component';
import { IconComponent } from '../../../icon/icon.component';
import { ModalComponent } from '../../modal/modal.component';
import { BaseModalComponent } from '../../modal/modal.models';

@Component({
  selector: 'aup-send-wm-measurements-modal',
  standalone: true,
  imports: [
    ModalComponent,
    FormFieldErrorComponent,
    IconComponent,
    ReactiveFormsModule,
    FormsModule,
    ModalButtonComponent,
    TranslateModule,
  ],
  templateUrl: './send-wm-measurements-modal.component.html',
  styleUrl: './send-wm-measurements-modal.component.scss',
})
export class SendWmMeasurementsModalComponent implements BaseModalComponent {
  @Input() id!: string;
  sendMeasurementsForm: FormGroup = new FormGroup({
    image: new FormControl<string>('', [Validators.required]),
    counter: new FormControl<number | null>(null, [
      Validators.required,
      Validators.max(999999999),
    ]),
  });
  loading: boolean = false;
  currentStep: 1 | 2 = 1;
  private _subscriptions: Subscription = new Subscription();
  constructor(
    protected modal: ModalService,
    private _toastr: ToastrService,
    private _wmService: WaterMeterService,
    private _translate: TranslateService,
  ) {}

  setContext(context: unknown): void {
    Object.assign(this, context);
  }

  onSubmit() {
    if (this.sendMeasurementsForm.valid) {
      this.loading = true;
      this._subscriptions.add(
        this._wmService
          .sendWmMeasurements(this.sendMeasurementsForm.value)
          .subscribe({
            next: (_) => {
              this.loading = false;
              this.modal.closeModal(this.id);
            },
            error: (e) => {
              console.error('Send measurements error:', e);
              this.loading = false;
            },
          }),
      );
    }
  }

  public goToNextStep() {
    this.currentStep = 2;
  }

  public goToPreviousStep() {
    this.currentStep = 1;
  }

  public closeModal() {
    this.modal.closeModal(this.id);
  }

  async uploadFile(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    try {
      const base64 = await this.convertToBase64(file);
      this.sendMeasurementsForm.patchValue({ image: base64 });
      this.sendMeasurementsForm.get('image')?.updateValueAndValidity();
    } catch (error: any) {
      this._toastr.error(
        this._translate.instant(error.message || 'notifications.error.generic'),
        'Error',
      );
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64String = reader.result as string;

        // Check if the file size exceeds 1MB
        const fileSizeInBytes = this.calculateBase64Size(base64String);
        const oneMBInBytes = 1 * 1024 * 1024;

        if (fileSizeInBytes > oneMBInBytes) {
          // Compress the image
          try {
            const compressedBase64 = await this.compressImage(
              base64String,
              0.7,
            ); // Adjust quality as needed
            resolve(compressedBase64);
          } catch (compressionError) {
            reject(compressionError);
          }
        } else {
          resolve(base64String);
        }
      };

      reader.onerror = (error) => reject(error);
    });
  }

  calculateBase64Size(base64String: string): number {
    // Calculate the size of a base64 string in bytes
    const base64Length = base64String.length - (base64String.indexOf(',') + 1);
    const padding =
      base64String.charAt(base64String.length - 2) === '='
        ? 2
        : base64String.charAt(base64String.length - 1) === '='
          ? 1
          : 0;
    return (base64Length * 3) / 4 - padding;
  }

  compressImage(base64String: string, quality: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = base64String;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject('Canvas context not available');
          return;
        }

        // Set canvas dimensions to the image's dimensions
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image onto the canvas
        ctx.drawImage(image, 0, 0);

        // Convert the canvas to a base64 string with reduced quality
        const compressedBase64 = canvas.toDataURL('image/jpeg', quality);

        resolve(compressedBase64);
      };

      image.onerror = (error) => reject(error);
    });
  }
}
