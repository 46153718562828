import {HttpInterceptorFn, HttpRequest, HttpResponse} from '@angular/common/http';
import {inject, Injector} from '@angular/core';

import {catchError, of, throwError} from 'rxjs';

import { AuthService } from '../services/auth.service';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const injector = inject(Injector); // Inject the Angular Injector
  const attachToken = (request: HttpRequest<unknown>): HttpRequest<unknown> => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && !request.url.includes('/users/refresh')) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return request;
  };

  // Modify request to attach the token
  const authReq = attachToken(req);

  // Pass the request to the next handler, along with any response handling logic
  return next(authReq).pipe(
    catchError((error) => {
      console.log('Error:', error);
      if (error.status === 401 || error.status === 0) {
        // If the response is 401, trigger the logout action
        const authService = injector.get(AuthService);

        authService.logout();
        // Return an empty observable to avoid propagating the error
        return of(new HttpResponse({ body: null }));
      }
      // For other errors, propagate the error as normal
      return throwError(() => error);
    }),
  );
};
