import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'customDate',
  pure: false,
  standalone: true,
})
export class CustomDatePipe
  extends DatePipe
  implements PipeTransform, OnDestroy
{
  private langChangeSubscription: Subscription;

  constructor(
    private translationService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
    // Call the parent class constructor
    const locale = translationService.currentLang === 'sl' ? 'sl-SI' : 'en-US';
    super(locale); // Pass default locale or empty for now
    this.langChangeSubscription = translationService.onLangChange.subscribe(
      () => {
        // Manually trigger change detection to refresh pipes
        this.cdr.detectChanges();
      },
    );
  }

  override transform(
    value: any,
    format?: string,
    timezone?: string,
    locale?: string,
  ): any {
    // If locale is undefined, use the one from TranslateService or fallback
    const currentLocale =
      typeof locale === 'string' && locale.trim() !== ''
        ? locale
        : this.translationService.currentLang === 'sl'
          ? 'sl-SI'
          : 'en-US';

    // Use the parent class method (DatePipe's transform) with the validated locale
    return super.transform(value, format, timezone, currentLocale);
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
