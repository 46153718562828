import { inject } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

function passwordsMatchValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const formGroup = control as FormGroup; // Type assertion
  const password = formGroup.get('password')?.value;
  const confirmPassword = formGroup.get('confirmPassword')?.value;
  if (
    password &&
    confirmPassword &&
    password !== confirmPassword &&
    formGroup.touched
  ) {
    return { passwordsMismatch: true };
  }
  return null;
}

function passwordStrengthValidator(translate: TranslateService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    console.log('translate', translate);
    const value: string = control.value || '';
    let errorMessage: string = translate.instant(
      'passwordValidation.baseMessage',
    );

    console.log('errorMessage', errorMessage);

    // Check for minimum length
    if (value.length < 8) {
      errorMessage += translate.instant('passwordValidation.minLength') + ', ';
    }

    // Check for uppercase letters
    if (!/[A-Z]/.test(value)) {
      errorMessage += translate.instant('passwordValidation.uppercase') + ', ';
    }

    // Check for lowercase letters
    if (!/[a-z]/.test(value)) {
      errorMessage += translate.instant('passwordValidation.lowercase') + ', ';
    }

    // Check for numbers
    if (!/[0-9]/.test(value)) {
      errorMessage += translate.instant('passwordValidation.number') + ', ';
    }

    // Check for symbols
    if (!/[^A-Za-z0-9]/.test(value)) {
      errorMessage += translate.instant('passwordValidation.symbol') + ', ';
    }

    // Trim the trailing comma and space
    if (errorMessage !== translate.instant('passwordValidation.baseMessage')) {
      errorMessage = errorMessage.slice(0, -2) + '.';
    } else {
      // No errors, return null
      return null;
    }

    console.log('errorMessage', errorMessage);

    return { passwordStrengthError: errorMessage };
  };
}

export { passwordsMatchValidator, passwordStrengthValidator };
