import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideToastr } from 'ngx-toastr';

import { routes } from './app.routes';
import { ToastNotificationComponent } from './components/utilities/toast-notification/toast-notification.component';
import { jwtInterceptor } from './interceptors/jwt.interceptor';
import { localeInterceptor } from './interceptors/locale.interceptor';

// translate loader
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Function to dynamically load and register locale data
export async function loadLocales() {
  try {
    const locale = await import('@angular/common/locales/sl'); // Replace 'sl' dynamically if needed
    const localeExtra = await import('@angular/common/locales/extra/sl');
    registerLocaleData(locale.default, 'sl', localeExtra.default);
    return 'sl-SI'; // Ensure a valid string is returned
  } catch (error) {
    console.error('Error loading locale data:', error);
    return 'en-US'; // Fallback to a default locale if there's an issue
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      }),
    ),
    TranslatePipe,
    provideHttpClient(withInterceptors([jwtInterceptor, localeInterceptor])),
    provideAnimations(),
    provideToastr({
      toastComponent: ToastNotificationComponent,
      positionClass: 'toast-top-right',
    }),
    {
      provide: LOCALE_ID,
      useFactory: loadLocales, // Dynamically set LOCALE_ID
    },
  ],
};
