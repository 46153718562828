@if (wmService.isLoading || wmService.areWmLoading) {
  <aup-loader [isDark]="true"></aup-loader>
} @else  {
  @if (wmService.isMultipleWm() | async) {
    <div class="max-w-[340px]">
    <aup-wm-dropdown
      id="123123123"
      (wmClick)="selectWm($event)"
    ></aup-wm-dropdown>
  </div>
  }
  @if (currentWmSn && currentWm && currentWm.info) {
    <div class="bg-white px-4 py-6 rounded-plate [max-w-100%] 2xl:max-w-[60%]">
      @if (currentWm.info && currentWm.info.location) {
        <div class="flex w-full flex-col">
          <div class="flex w-full bg-[#EFF1F5] rounded-full py-2 px-5 mb-8">
            <span class="text-lg font-bold uppercase text-brand-primary">{{ 'meter_info.location_info' | translate }}</span>
          </div>
          <div class="flex flex-col">
            <table class="w-full text-base">
              <tbody>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.address' | translate }}</td>
                <td class="py-4">{{currentWm.info.location.address}}</td>
              </tr>
              <tr class="">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.location_photo' | translate }}</td>
                <td class="py-4 max-w-[400px]">
                  @for (image of currentWm.info.location.images; track $index) {
                    @if (image) {
                      <img

                        src="{{image.image_url}}" alt="Location photo" class="mb-4 inline-block align-top" />
                    }
                  }
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
      @if (currentWm.info && currentWm.info.pits) {
        <div class="flex w-full flex-col">
          <div class="flex w-full bg-[#EFF1F5] rounded-full py-2 px-5 mb-8">
            <span class="text-lg font-bold uppercase text-brand-primary">{{ 'meter_info.pit_info' | translate }}</span>
          </div>
          <div class="flex flex-col">
            <table class="text-base">
              <tbody>
                @for (pit of currentWm.info.pits; track $index; let last = $last) {
                  <tr class="border-b-[1px]">
                    <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.pit' | translate }}</td>
                    <td class="py-4">{{pit.name}}</td>
                  </tr>
                  <tr>
                    <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]"><p >{{ 'meter_info.pit_photo_before_change' | translate }}:</p></td>
                    <td class="py-4 max-w-[400px]">
                      @if (pit.image_url) {
                        <img

                          src="{{pit.image_url}}" alt="Photo of the pit before change" class="mb-4" />
                      }
                    </td>
                  </tr>
                  @if (!last) {
                    <hr />
                  }
                }
              </tbody>
            </table>
          </div>
        </div>
      }
      @if (currentWm.info && currentWm.info.old) {
        <div class="flex w-full flex-col">
          <div class="flex w-full bg-[#EFF1F5] rounded-full py-2 px-5 mb-8">
            <span class="text-lg font-bold uppercase text-brand-primary">{{ 'meter_info.old_meter_info' | translate }}</span>
          </div>
          <div class="flex flex-col">
            <table class="text-base">
              <tbody>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.current_serial_num' | translate }}:</td>
                <td class="py-4">{{currentWm.info.old.serial_number}}</td>
              </tr>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.old_meter_photo' | translate }}:</td>
                <td class="py-4 max-w-[400px]">
                  @if (currentWm.info.old.serial_number_image) {
                    <img

                      src="{{currentWm.info.old.serial_number_image}}"  alt="Photo of serial number of old Water Meter" class="mb-4" />
                  }
                </td>
              </tr>
              <tr>
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.current_counter_val' | translate }}:</td>
                <td class="py-4">
                  <span>{{currentWm.info.old.counter | numberMutator}} m3</span>
                  @if (currentWm.info.old.counter_image) {
                    <br />
                    <img

                      src="{{currentWm.info.old.counter_image}}" alt="Photo of old Water Meter counter" class="mb-4" />
                  }
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      }

      @if (currentWm.info && currentWm.info.new) {
        <div class="flex w-full flex-col">
          <div class="flex w-full bg-[#EFF1F5] rounded-full py-2 px-5 mb-8">
            <span class="text-lg font-bold uppercase text-brand-primary">{{ 'meter_info.new_meter_info' | translate }}</span>
          </div>
          <div class="flex flex-col">
            <table class="text-base">
              <tbody>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.new_serial_num' | translate }}:</td>
                <td class="py-4">{{currentWm.info.new.serial_number}}</td>
              </tr>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.radio_address' | translate }}:</td>
                <td class="py-4">{{currentWm.info.new.radio_address}}</td>
              </tr>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.type' | translate }}:</td>
                <td class="py-4">{{currentWm.info.new.type}}</td>
              </tr>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.diameter' | translate }}:</td>
                <td class="py-4">{{currentWm.info.new.diameter}}</td>
              </tr>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.date_of_change' | translate }}:</td>
                <td class="py-4">{{currentWm.info.new.date_of_change}}</td>
              </tr>
              <tr class="border-b-[1px]">
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.new_meter_photo' | translate }}:</td>
                <td class="py-4 max-w-[400px]">
                  @if (currentWm.info.new.serial_number_image) {
                    <img

                      src="{{currentWm.info.new.serial_number_image}}" class="mb-4" alt="Photo of serial number of old Water Meter" />
                  }
                </td>
              </tr>
              <tr>
                <td class="mr-8 py-4 pr-8 align-top font-bold max-w-[250px] w-[250px]">{{ 'meter_info.new_counter_val' | translate }}:</td>
                <td class="py-4">
                  <span>{{currentWm.info.new.counter | numberMutator}} m3</span>
                  @if (currentWm.info.new.counter_image) {
                    <br />
                    <img

                      src="{{currentWm.info.new.counter_image}}" alt="Photo of new counter" />
                  }
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="flex items-start justify-start">
      <span class="text-lg text-gray-500">{{ 'meter_info.no_info' | translate }}</span>
    </div>
  }
}

