import { AsyncPipe, NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { switchMap } from 'rxjs';

import { ExpandableImageDirective } from '../../../directives/expandable-image.directive';
import { FlowbiteMarkDirective } from '../../../directives/flowbite-mark.directive';
import { IMeterInfo } from '../../../models/IWaterMeters.types';
import { NumberMutatorPipe } from '../../../pipes/number-mutator.pipe';
import { WaterMeterService } from '../../../services/water-meter.service';
import { WmDropdownComponent } from '../../utilities/dropdowns/wm-dropdown/wm-dropdown.component';
import { IconComponent } from '../../utilities/icon/icon.component';
import { LoaderComponent } from '../../utilities/loader/loader.component';

// components

@Component({
  selector: 'aup-meter-info',
  standalone: true,
  templateUrl: './meter-info.component.html',
  styleUrl: './meter-info.component.scss',
  imports: [
    FlowbiteMarkDirective,
    AsyncPipe,
    NgClass,
    LoaderComponent,
    IconComponent,
    WmDropdownComponent,
    TranslateModule,
    NumberMutatorPipe,
    ExpandableImageDirective,
  ],
})
export class MeterInfoComponent implements OnInit {
  @ViewChildren('wmInfoEl') elements!: QueryList<ElementRef>;

  meterInfoMap: Map<string, IMeterInfo> = new Map<string, IMeterInfo>();
  currentWmSn: string = '';
  constructor(
    private _route: ActivatedRoute,
    public wmService: WaterMeterService,
  ) {}

  ngOnInit(): void {
    this.wmService
      .getMeterInfo()
      .pipe(
        switchMap((info) => {
          if ('data' in info && info.data.length > 0) {
            console.log('Meter info:', info.data);
            this.meterInfoMap = new Map<string, IMeterInfo>(
              info.data.map((meter) => [meter.device_sn, meter]),
            );
          }
          // If the meter info is successfully retrieved, proceed to get route params
          return this._route.params;
        }),
      )
      .subscribe({
        next: (params) => {
          if (params['id']) {
            this.currentWmSn = params['id'];
            this.wmService.setCurrentWaterMeter(params['id']);
          } else {
            if (this.meterInfoMap) {
              const currentWm = this.wmService.currentWmValue?.device_sn;
              if (currentWm) {
                this.currentWmSn = currentWm;
              } else {
                this.currentWmSn = this.meterInfoMap.keys().next().value;
              }
              this.wmService.setCurrentWaterMeter(this.currentWmSn);
            }
          }
        },
        error: (error) => {
          console.error('Error:', error);
        },
      });
  }

  get currentWm(): IMeterInfo | undefined {
    console.log('getter start', this.currentWmSn);
    if (!this.currentWmSn) {
      this.currentWmSn = this.meterInfoMap.keys().next().value;
    }
    return this.meterInfoMap.get(this.currentWmSn);
  }

  selectWm(wm_sn: string): void {
    this.currentWmSn = wm_sn;
    this.wmService.setCurrentWaterMeter(wm_sn);
  }
}
