import { HttpInterceptorFn } from '@angular/common/http';

export const localeInterceptor: HttpInterceptorFn = (req, next) => {
  const locale = navigator.language || 'en-US';
  const modifiedReq = req.clone({
    setHeaders: {
      'Accept-Language': locale.includes('sl') ? 'sl-SI' : 'en-US',
    },
  });
  return next(modifiedReq);
};
