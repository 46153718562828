import { AsyncPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AnnouncmentsService } from '../../../services/announcments.service';
import { LoaderComponent } from '../../utilities/loader/loader.component';
import {CustomDatePipe} from "../../../pipes/custom-date.pipe";

// components

@Component({
  selector: 'aup-announcements',
  standalone: true,
  templateUrl: './announcements.component.html',
  styleUrl: './announcements.component.scss',
  imports: [
    TranslateModule,
    AsyncPipe,
    DatePipe,
    LoaderComponent,
    CustomDatePipe,
  ],
})
export class AnnouncementsComponent {
  constructor(protected ancService: AnnouncmentsService) {}
}
