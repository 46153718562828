import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[aupExpandableImage]',
  standalone: true,
})
export class ExpandableImageDirective implements OnInit {
  private isExpanded = false;
  private expandIcon!: HTMLElement;
  private container: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    // Create a container div around the image element
    this.container = this.renderer.createElement('div');
  }

  ngOnInit() {
    // Wrap the image with a div container
    const parent = this.el.nativeElement.parentNode;
    this.renderer.insertBefore(parent, this.container, this.el.nativeElement);
    this.renderer.appendChild(this.container, this.el.nativeElement);

    // Set up initial styles for the container and image
    this.renderer.setStyle(this.container, 'position', 'relative');
    this.renderer.setStyle(this.container, 'display', 'inline-block');
    this.renderer.setStyle(this.container, 'max-height', '150px'); // Limit the initial height of the container
    this.renderer.setStyle(this.container, 'overflow', 'hidden'); // Hide the rest of the image
    this.renderer.setStyle(
      this.container,
      'transition',
      'max-height 0.4s ease',
    ); // Smooth transition

    // Set cursor style on image
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');

    // Add the expand icon
    this.addExpandIcon();
  }

  @HostListener('click')
  toggleExpand() {
    if (this.isExpanded) {
      // Collapse the container to hide part of the image
      this.renderer.setStyle(this.container, 'max-height', '150px');
      this.expandIcon.innerHTML = '+'; // Show plus icon
    } else {
      // Expand the container to show the full image
      this.renderer.setStyle(this.container, 'max-height', '1000px'); // Adjust based on the expected max image height
      this.expandIcon.innerHTML = '-'; // Show minus icon
    }

    this.isExpanded = !this.isExpanded;
  }

  private addExpandIcon() {
    // Create the icon element
    this.expandIcon = this.renderer.createElement('span');
    this.renderer.addClass(this.expandIcon, 'expand-icon');

    // Style the icon to ensure it's visible
    this.renderer.setStyle(this.expandIcon, 'position', 'absolute');
    this.renderer.setStyle(this.expandIcon, 'bottom', '10px');
    this.renderer.setStyle(this.expandIcon, 'right', '10px');
    this.renderer.setStyle(this.expandIcon, 'font-size', '24px');
    this.renderer.setStyle(this.expandIcon, 'color', 'white');
    this.renderer.setStyle(this.expandIcon, 'background', 'rgba(0, 0, 0, 0.7)');
    this.renderer.setStyle(this.expandIcon, 'padding', '10px');
    this.renderer.setStyle(this.expandIcon, 'border-radius', '50%');
    this.renderer.setStyle(this.expandIcon, 'width', '40px');
    this.renderer.setStyle(this.expandIcon, 'height', '40px');
    this.renderer.setStyle(this.expandIcon, 'display', 'flex');
    this.renderer.setStyle(this.expandIcon, 'justify-content', 'center');
    this.renderer.setStyle(this.expandIcon, 'align-items', 'center');
    this.renderer.setStyle(this.expandIcon, 'z-index', '10');
    this.renderer.setStyle(this.expandIcon, 'cursor', 'pointer');
    // user select none
    this.renderer.setStyle(this.expandIcon, 'user-select', 'none');

    // Set inner text or use an icon library for a better icon
    this.expandIcon.innerHTML = '+'; // Default state is collapsed

    // Append the icon to the container
    this.renderer.appendChild(this.container, this.expandIcon);

    // Listen for clicks on the icon as well
    this.renderer.listen(this.expandIcon, 'click', (event) => {
      event.stopPropagation(); // Prevent the click from bubbling up to the image
      this.toggleExpand();
    });
  }
}
