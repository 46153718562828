import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule, Validators,
} from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { first, Subscription } from 'rxjs';

import { ModalService } from '../../../../../services/modal.service';
import { WaterMeterService } from '../../../../../services/water-meter.service';
import { ModalButtonComponent } from '../../../buttons/modal-button/modal-button.component';
import { ModalComponent } from '../../modal/modal.component';
import { RenameWmModalComponent } from '../../modal/modal.models';

@Component({
  selector: 'aup-rename-watermeter-modal',
  standalone: true,
  imports: [
    ModalComponent,
    FormsModule,
    ReactiveFormsModule,
    ModalButtonComponent,
    TranslateModule,
  ],
  templateUrl: './rename-watermeter-modal.component.html',
  styleUrl: './rename-watermeter-modal.component.scss',
})
export class RenameWatermeterModalComponent
  implements RenameWmModalComponent, OnInit, OnDestroy
{
  @Input() id!: string;
  renameWaterMeterForm: FormGroup = new FormGroup({
    wmAlias: new FormControl<string>('', [Validators.maxLength(255)]),
  });
  loading: boolean = false;
  private _subscriptions: Subscription = new Subscription();

  constructor(
    protected modal: ModalService,
    private _waterMeterService: WaterMeterService,
  ) {}

  ngOnInit() {
    const currentWmSub = this._waterMeterService.currentWm$
      .pipe(first())
      .subscribe({
        next: (wm) => {
          this.renameWaterMeterForm.patchValue({ wmAlias: wm?.alias });
          this.renameWaterMeterForm.updateValueAndValidity();
        },
      });

    this._subscriptions.add(currentWmSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  setContext(context: unknown): void {
    Object.assign(this, context);
  }

  onSubmit() {
    if (this.renameWaterMeterForm.valid) {
      this.loading = true;
      const renameSub = this._waterMeterService
        .renameWatermeter(this.renameWaterMeterForm.value)
        .subscribe({
          next: (_) => {
            console.log('Water meter renamed', _);
            this.loading = false;
            this.renameWaterMeterForm.reset();
            this.modal.closeModal(this.id);
          },
          error: (e) => {
            console.error('Rename water meter error:', e);
            this.loading = false;
          },
        });
      this._subscriptions.add(renameSub);
    }
  }

  removeWmAlias(e: MouseEvent) {
    e.preventDefault();
    this.renameWaterMeterForm.patchValue({ wmAlias: '' });
    this.renameWaterMeterForm.updateValueAndValidity();
    this.onSubmit();
  }
}
