import { AsyncPipe, DecimalPipe, NgClass } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Subscription } from 'rxjs';

import { FlowbiteMarkDirective } from '../../../directives/flowbite-mark.directive';
import { WaterMeterService } from '../../../services/water-meter.service';
import { ChartComponent } from '../../utilities/chart/chart.component';
import { WmDropdownComponent } from '../../utilities/dropdowns/wm-dropdown/wm-dropdown.component';
import { IconComponent } from '../../utilities/icon/icon.component';
import { LoaderComponent } from '../../utilities/loader/loader.component';

@Component({
  selector: 'aup-problem-reporting',
  standalone: true,
  templateUrl: './problem-reporting.component.html',
  styleUrl: './problem-reporting.component.scss',
  imports: [
    ChartComponent,
    DecimalPipe,
    FlowbiteMarkDirective,
    IconComponent,
    RouterLink,
    AsyncPipe,
    NgClass,
    ReactiveFormsModule,
    LoaderComponent,
    WmDropdownComponent,
    TranslateModule,
  ],
})
export class ProblemReportingComponent implements OnDestroy {
  currentStep: 1 | 2 | 3 = 1;
  problemReportForm: FormGroup;

  isLoading: boolean = false;
  private _subscriptions: Subscription = new Subscription();
  constructor(
    protected wmService: WaterMeterService,
    private _translate: TranslateService,
    private _toastr: ToastrService,
  ) {
    this.problemReportForm = new FormGroup({
      image: new FormControl<string>('', [Validators.required]),
      waterMeter: new FormControl<number | null>(
        this.wmService.currentWmValue?.meter_id || null,
        [Validators.required],
      ),
      description: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
    });
    const currentWmSub = this.wmService.currentWm$.subscribe({
      next: (wm) => {
        this.problemReportForm.patchValue({
          waterMeter: wm?.meter_id,
        });
      },
      error: (e) => {
        console.error('Problem report error:', e);
      },
    });
    this._subscriptions.add(currentWmSub);
  }

  onSubmit() {
    if (this.problemReportForm.valid) {
      this.isLoading = true;
      this.wmService.reportProblem(this.problemReportForm.value).subscribe({
        next: async (_) => {
          this.problemReportForm.reset();
          this.problemReportForm.patchValue({
            waterMeter: this.wmService.currentWmValue?.meter_id,
          });
          this.currentStep = 1;
          this.isLoading = false;
        },
        error: (e) => {
          console.error('Problem report error:', e);
        },
      });
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  async selectWaterMeter(wmSn: string) {
    const wms = await firstValueFrom(this.wmService.wms$);
    const wm = wms?.find((wm) => wm.device_sn === wmSn);
    this.problemReportForm.patchValue({
      waterMeter: wm?.meter_id,
    });
    this.wmService.setCurrentWaterMeter(wmSn);
  }

  async uploadFile(event: Event) {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    try {
      const base64 = await this.convertToBase64(file);
      this.problemReportForm.patchValue({
        image: base64,
      });

      this.problemReportForm.get('image')?.updateValueAndValidity();
    } catch (error: any) {
      this._toastr.error(
        this._translate.instant(error.message || 'notifications.error.generic'),
        'Error',
      );
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64String = reader.result as string;

        // Check if the file size exceeds 1MB
        const fileSizeInBytes = this.calculateBase64Size(base64String);
        const oneMBInBytes = 1 * 1024 * 1024;

        if (fileSizeInBytes > oneMBInBytes) {
          // Compress the image
          try {
            const compressedBase64 = await this.compressImage(
              base64String,
              0.7,
            ); // Adjust quality as needed
            resolve(compressedBase64);
          } catch (compressionError) {
            reject(compressionError);
          }
        } else {
          resolve(base64String);
        }
      };

      reader.onerror = (error) => reject(error);
    });
  }

  calculateBase64Size(base64String: string): number {
    // Calculate the size of a base64 string in bytes
    const base64Length = base64String.length - (base64String.indexOf(',') + 1);
    const padding =
      base64String.charAt(base64String.length - 2) === '='
        ? 2
        : base64String.charAt(base64String.length - 1) === '='
          ? 1
          : 0;
    return (base64Length * 3) / 4 - padding;
  }

  compressImage(base64String: string, quality: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = base64String;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject('Canvas context not available');
          return;
        }

        // Set canvas dimensions to the image's dimensions
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image onto the canvas
        ctx.drawImage(image, 0, 0);

        // Convert the canvas to a base64 string with reduced quality
        const compressedBase64 = canvas.toDataURL('image/jpeg', quality);

        resolve(compressedBase64);
      };

      image.onerror = (error) => reject(error);
    });
  }

  goToNextStep() {
    this.currentStep++;
  }

  goToPreviousStep() {
    this.currentStep--;
  }
}
